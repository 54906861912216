<template>
  <div class="make">
    <iframe id="FilmstackerWidget"
      style="width:100%;border:none;padding:0;"
      title="The West is Burning - powered by Filmstacker"
      height="100%"
      allow="fullscreen;autoplay;camera;geolocation;web-share;clipboard-write;"
      name="fsr-widget"
      :src="widgetSrc">
      <p>Your browser does not support iframes.</p>
      <p>
        Open in a new page: <a href="https://app.filmstacker.com/stack/discover/project/wallowa-resources-the-west-is-burning" target="_blank">https://app.filmstacker.com/stack/discover/project/wallowa-resources-the-west-is-burning</a>
      </p>
    </iframe>
  </div>
</template>

<script>
/**
 * Filmstacker Stack IDs for testing:
 * "angel_Logging-in-Oregon-and-Its-Consequences_20190613"
 * "angel_Walking-Through-an-Abandoned-Mill_20190613"
 */
export default {
  name: 'make',
  props: {
    id: String,
  },
  computed: {
    widgetSrc() {  
      return (this.id) ? `https://app.filmstacker.com/stack/player/wallowa-resources-the-west-is-burning/${this.id}?widget=wib` : `https://app.filmstacker.com/stack/discover/project/wallowa-resources-the-west-is-burning?widget=wib`
    }
  }
}
</script>

<style lang="stylus">
  .west-is-burning--page-make {
    height: 100%
    .container {
      padding: 0;
    }

    .make {
      height: 100%
      width: 100%
    }

    #FilmstackerWidget {
      height: 100%
    }
  }
</style>
